import styled from 'styled-components';

export default styled.div`
  border-radius: var(--border_radius_card);
  overflow: hidden;
  .total-price {
    font-size: var(--base-font-24-18);
    font-weight: bold;
    color: var(--color_sale_text);
  }
  .main-title {
    color: rgba(var(--color_body_text_rgb), 0.6);
    max-width: 180px;
  }

  .item-block {
    background: var(--color_card, #fff);
  }

  .item-goods-block {
  }
  .shipping-price {
    font-weight: 600;
  }

  .item-goods-block-content {
    border-bottom: 1px solid rgba(51, 51, 51, 0.08);
    padding: 24px 16px 16px 24px;
    .goods-item-one {
      + .goods-item-one {
        padding-top: 16px;
      }
      .goods-name {
        color: var(--color_body_text);
      }
      .count {
        color: rgba(var(--color_body_text_rgb), 0.6);
      }
      .origin-price {
        color: var(--color_body_text);
        &.is-discount {
          color: rgba(var(--color_body_text_rgb), 0.4);
        }
      }
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0 !important;
      }
      .price-detail {
        align-items: center;
        .origin-price {
          margin-left: 4px;
        }
      }
    }
    .invalid-title {
      font-size: 14px;
      line-height: 20px;
      color: rgba(236, 91, 86, 1);
      margin: 16px 0 4px;
      font-weight: 500;
    }
    .list-edit {
      margin-top: 16px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: rgba(var(--color_body_text), 0.4);
      > span {
        cursor: pointer;
      }
    }
  }
  /* .sum-wrapper {
        border-bottom: 1px solid rgba(51, 51, 51, 0.08);
        padding-bottom: 12px;
    } */

  .item-block + .item-block {
    padding: var(--pm-24-18);
    box-sizing: border-box;
    .item-block-item + .item-block-item {
      margin-top: 24px;
    }
  }
  .sum-count-wrapper {
    padding: 0 var(--pm-24-16);
    background: var(--color_card, #fff);
    padding-bottom: 36px;
    padding-top: 18px;

    border-top: 1px solid rgba(var(--color_body_text_rgb), 0.08);
    .font-light {
      color: rgba(var(--color_body_text_rgb), 0.6);
    }
  }
  .sing-page-pay-wrapper {
    margin-bottom: 80px;
    background: var(--color_card, #fff);
    .pay-action-wrapper {
      padding: 0 var(--pm-24-16) 18px;
      button {
        width: 100%;
      }
    }
    .safe-pay-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 var(--pm-24-16) 24px;
      .description {
        flex: 1;
        margin-left: 6px;
        color: #8c8c8c;
        font-size: 14px;
      }
    }
  }
  .discount-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    .discount-code-input {
      height: 44px !important;
      flex: 1;
    }
    .zent-input-wrapper {
      border-radius: var(--border_radius_form) 0 0 var(--border_radius_form) !important;
      border-color: var(--color_text_field_border) !important;
      background-color: var(--color_text_field);
    }
    button {
      border-radius: 0 var(--border_radius_form) var(--border_radius_form) 0 !important;
    }
  }

  .discount-input-code-detail {
    display: inline-flex;
    margin-top: 8px;
    padding: 0 8px;
    background: rgba(var(--color_sale_text_rgb), 0.1);
    height: 40px;
    align-items: center;
    border-radius: var(--border_radius_tag);
    .discount-code-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      fill: var(--color_sale_text);
      margin-right: 10px;
      vertical-align: middle;
    }
    .discount-code {
      max-width: 130px;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      font-size: 14px;
      line-height: 14px;
      vertical-align: text-bottom;
    }
    .discount-del-code-icon {
      width: 16px;
      height: 16px;
      margin-left: 16px;
      vertical-align: middle;
      display: inline-block;
      cursor: pointer;
    }
  }

  .discount-code-invalid-text {
    display: flex;
    padding: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #982424;
    line-height: 20px;
    margin-bottom: 16px;
    background: rgba(152, 36, 36, 0.06);
    border-radius: var(--border_radius_tag);
    .discount-code-invalid-text-del-icon {
      text-align: right;
      flex: 1;
    }
  }

  .discount-free-shipping-desc {
    font-size: 14px;
    font-weight: 400;
    color: #1a1a1b;
    line-height: 20px;
    padding: 12px;
    background: #f7f7f7;
    margin-bottom: 16px;
  }

  .discount-code-invalid-del-icon {
    width: 16px;
    height: 16px;
    fill: #982424;
    display: inline-block;
  }

  .verify-discount-code {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #c93c33;
    line-height: 12px;
  }

  .shipping-item-block-item {
    align-items: initial;
  }

  .ship-free-discount-code {
    height: 40px;
    margin: 8px 0;
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    background: rgba(var(--color_activity_label_rgb), 0.1);
    border-radius: var(--border_radius_tag);
    .ship-free-discount-code-icon {
      width: 16px;
      height: 16px;
      fill: var(--color_activity_label);
      display: inline-block;
      margin-right: 2px;
    }
    .ship-free-discount-text {
      word-break: break-word;
      word-wrap: break-word;
      font-size: 14px;
      font-weight: 600;
      color: var(--color_activity_label);
      line-height: 14px;
    }
  }

  .total-goods-price {
    font-weight: bold;
    line-height: 14px;
    .icon {
      width: 16px;
      height: 16px;
      fill: rgba(var(--color_body_text_rgb), 0.8);
    }
  }
  .activity-label-price {
    color: var(--color_activity_label);
  }

  flex: 1;
  .layout-goods {
    border-top: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
    padding: 16px 0;
    line-height: 30px;
    font-size: var(--base-font-14-12);
  }
  .layout-cell-right {
    text-align: right;
  }
  .layout-total {
    padding-top: 23px;
    line-height: 24px;
    font-size: var(--base-font-14-12);
  }
  #little-total {
    padding-top: 12px;
  }
  .multi-currency-total-price {
    color: ${(props) => props.theme.colorBodyTexts['60']};
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
  }
  .multi-currency-total-price-desc {
    color: ${(props) => props.theme.colorBodyTexts['60']};
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }
  .payment-request-button-wrap {
    position: relative;
    &.payment-request-button-disabled::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: not-allowed;
      z-index: 2;
      opacity: 0.3;
      background-color: #fff;
    }
    iframe {
      border-radius: var(--border_radius_btn) !important;
      height: 40px !important;
    }
  }
  .agreement-checkbox-wrap {
    padding: 0 var(--pm-24-16) 24px;
  }
  .agreement-content {
    font-size: 12px;
    line-height: 20px;
    color: rgb(var(--color_body_text_rgb), .6);
    text-align: left;
    .agreement-text {
      margin-left: 2px;
      overflow: hidden;
      .agreement-title {
        color: var(--color_body_text);
      }
      >* {
        margin-left: 2px;
      }
    }
  }
  @media screen and (max-width: 750px) {
    .safe-pay-form-wrapper {
      position: fixed;
      bottom: 0;
      background: #fff;
      z-index: 100;
      left: 0;
      right: 0;
      .pay-action-wrapper {
        padding: 12px 16px;
      }
      .safe-pay-wrapper {
        display: none;
      }
    }

    #little-total {
      /* padding-top: 4px; */
    }
    .sum-count-wrapper {
      padding-bottom: 28px;
      margin-bottom: 0px;
    }
    .sing-page-pay-wrapper {
      margin-bottom: 0px;
    }
    .item-goods-block-content {
      padding: 0 16px 16px;
    }
    .item-goods-block-content {
    }
    .agreement-checkbox-wrap {
      padding: 12px 16px 0;
    }
  }
  .invalid-reason {
    font-size: 12px;
    line-height: 17px;
    padding: 6px 8px;
    color: rgba(255, 77, 79, 1);
    background-color: rgba(236, 91, 86, 0.05);
    margin-top: 4px;
  }
`;
