import { Dialog } from 'zent';
import Style from './style';

const { openDialog } = Dialog;

export const openPayScanDialog = ({ name = '', qrCode, intl }) => {
  const way = name || intl.formatMessage({ id: '1d6e9d5c24c14dae927291c89a4bf777', defaultMessage: '手机' });
  openDialog({
    title: intl.formatMessage({ defaultMessage: '扫码支付', id: 'tong.client.scan_pay' }),
    children: (
      <Style>
        <div className='scan-wrapper'>
          <img src={qrCode} alt={name} />
        </div>
        <div className='scan-pay-tips'>
          {intl.formatMessage({ defaultMessage: '请打开{way},扫码支付', id: 'scan.qrcode.for_pay' }, { way })}
        </div>
      </Style>
    ),
    maskClosable: false,
  });
};
